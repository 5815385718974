<template>
    <router-link :title="this.label" :to="{ name:this.iconKey }" class="ExpertiseIcon">
        <svg class="ExpertiseIcon-svg">
            <use v-bind:xlink:href="`#symbol-${this.iconKey}`"></use>
        </svg>
        <div class="ExpertiseIcon-info">
            <h3 class="ExpertiseIcon-title" v-html="this.wrappedLabel"></h3>
        </div>
    </router-link>
</template>

<script>
    export default {
        name: 'ExpertiseIcon',
        props: {
            iconKey: String,
            label: String,
            wrappedLabel: String,
            headerWord: String
        }
    };
</script>
